import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
/**Toast */
import { toast } from 'react-toastify';
import { toastOptions, validError } from '../../constants/helpers';
/**archivos constante */
import { endpoint } from '../../constants/endpoint';
/**Componentes */
import { AutocompleteSector } from '../../components/autocomplete/AutocompleteSector';
import { Loading } from '../loading/Loading';

export const FormularioEdit = () => {
    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    /** Estado de Cargando */
    const [cargando, setCargando] = useState(false);
    /** Data del Numero de telefono obtenido */
    const [dataNumber, setDataNumber] = useState([])
    console.log(dataNumber)
    /**Obtener las parroquias y sectores  */
    const [parroquias, setParroquias] = useState([]);
    /**Valor para el submit del autocomplete */
    const [valueSector, setValueSector] = useState();

    /** Valor del Buscar */
    const [search, setSearch] = useState('');

    let numeroId = dataNumber.map((i) => i.id)

    const getParroquiasSectores = async () => {
        const url = `${endpoint}/sector3/?format=json`;
        await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            setParroquias(response.data)
        }).catch((err) => {
            console.log(err, ' aqui esta el error ')
        })
    }

    useEffect(() => {
        getParroquiasSectores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const buscarNumero = async () => {
        if (search.trim().length > 10) {
            setCargando(true)
            const url = `${endpoint}/cliente_public/?search=${search}`
            await axios.get(url).then((response) => {
                if (response.data[0] === undefined) {
                    toast.info(`El numero de telefono no coincide con nuestros registros!`, toastOptions);
                } else {
                    setDataNumber(response.data)
                }
                setTimeout(() => {
                    setCargando(false)
                    setSearch('')
                }, [500])
            }).catch(() => {
                toast.error(`Opps, Ha ocurrido un error`, toastOptions);
                setCargando(false)
            })
        }
    }



    useEffect(() => {
        buscarNumero()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    /**Funcion de submit para el registro */
    const onSubmit = async (data) => {
        setCargando(true)
        let resultados = {};
        if (valueSector === undefined) {
            resultados = { phone: data.phone, name: data.name }
            setValueSector(parroquias.sector)
        } else if (valueSector.data.length === 0) {
            resultados = { ...data, parroquia: valueSector.parroquia_id, sector: valueSector.sector_id }
            setValueSector(parroquias.sector)
        } else {
            resultados = { ...data, parroquia: valueSector.data[0].id, sector: valueSector.sector_id };
            setValueSector(parroquias.sector)
        }

        const url = `${endpoint}/cliente/${numeroId}/`
        await axios.put(url, resultados, {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' }
        }).then(() => {
            toast.success(`Actualizacion satisfactoria!`, toastOptions);
            reset()
            setCargando(false)
        }).catch((err) => {
            setCargando(false)
            const error = validError(err.response.data)
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, toastOptions);
            setCargando(false)
        })
    }


    return (
        <Fragment>

            <div className='sm:w-2/3 w-full lg:px-0 mx-auto flex flex-col'>
                <div className='pt-2 rounded bg-gray-200'>
                    <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Buscar el Teléfono a editar</label>
                    <input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type='number'
                        placeholder='Ingrese un Teléfono'
                        className='bg-gray-200 rounded w-full text-gray-700 focus:outline-none placeholder:text-center border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                    />
                </div>
            </div>

            {/* Linea separador */}
            <hr className='my-5' />


            <form onSubmit={handleSubmit(onSubmit)} className='sm:w-2/3 w-full px-4 lg:px-0 mx-auto flex flex-col space-y-4'>
                {dataNumber.length > 0 && (
                    <Fragment>
                        <div className='mb-3 pt-2 rounded bg-gray-200'>
                            <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Teléfono  <span className='text-gray-dark text-xs font-medium'> - (Ej. 04149999999)</span> </label>
                            <input
                                id='phone'
                                type='number'
                                placeholder='Ingrese el nuevo Teléfono'
                                className='bg-gray-200 rounded w-full text-gray-700 focus:outline-none placeholder:text-center border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                                {...register('phone', { required: true, maxLength: 11 })}
                                value={dataNumber[0].phone}
                            />
                            {errors.phone && errors.phone.type === "required" && <div className='text-red-900 p-1 text-sm'>Debe ingresar un Teléfono</div>}
                            {errors.phone && errors.phone.type === "maxLength" && <div className='text-red-900 p-1 text-sm'>El numero debe ser de 11 digitos</div>}
                        </div>

                        <div className=' pt-3 rounded bg-gray-200'>
                            <label htmlFor='name' className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3' >Nombre y Apellido</label>
                            <input
                                id='name'
                                type='text'
                                placeholder='Ingrese el nombre y apellido'
                                {...register('name')}
                                className='bg-gray-200 rounded w-full text-gray-700 placeholder:text-center focus:outline-none border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                                value={dataNumber[0].name}
                            />
                        </div>

                        <div className=' pt-3 rounded bg-gray-200'>
                            <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Parroquia - Sector</label>
                            <AutocompleteSector
                                parroquias={parroquias}
                                valueSector={valueSector}
                                setValueSector={setValueSector}
                                personData={dataNumber}
                            />
                        </div>

                        {cargando ? (
                            <Loading cargando={cargando} />
                        ) : (
                            <button
                                disabled={cargando}
                                className='bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200'
                                type='submit'
                            >
                                Actualizar
                            </button>
                        )}
                    </Fragment>
                )}

            </form>
        </Fragment>
    )
}
