import { Fragment } from 'react'
import { Routes, Route } from 'react-router-dom'

/**Formulario */
import { Formulario } from '../pages/formulario/Formulario'


export const AppRouter = () => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Formulario />} />
            </Routes>
        </Fragment>
    )
}
