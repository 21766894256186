export const images = {
    logoNetwork: require('../images/gnetworkisotipo.png'),
    logoNetwork2: require('../images/gnetworkisotipo2.png'),
    logoNetwork3: require('../images/gnetworklogo.png'),
    logoNetwork4: require('../images/gnetworklogo2.png'),
    logoNetwork5: require('../images/gnetworklogo3.png'),
    logoNetwork6: require('../images/logo-g.png'),
    logoNetwork7: require('../images/portada.jpeg'),
    logoNetwork8: require('../images/callcenter.jpg'),



}
