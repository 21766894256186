import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
/**Toast */
import { toast } from 'react-toastify';
import { toastOptions, validError } from '../../constants/helpers';
/**archivos constante */
import { endpoint } from '../../constants/endpoint';
/**Componentes */
import { AutocompleteSector } from '../../components/autocomplete/AutocompleteSector';
import { Loading } from '../loading/Loading';


export const FormularioRegistro = () => {

    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm({
        defaultValues: {
            name: '',
            phone: '',
        }
    });

    /** Estado de Cargando */
    const [cargando, setCargando] = useState(false);
    /**Obtener las parroquias y sectores  */
    const [parroquias, setParroquias] = useState([]);
    /**Valor para el submit del autocomplete */
    const [valueSector, setValueSector] = useState(parroquias.sector);

    const getParroquiasSectores = async () => {
        const url = `${endpoint}/sector3/?format=json`;
        await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            setParroquias(response.data)
        }).catch((err) => {
            console.log(err, ' aqui esta el error ')
        })
    }

    useEffect(() => {
        getParroquiasSectores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**Funcion de submit para el registro */
    const onSubmit = async (data) => {
        setCargando(true)
        let resultados = {};
        if (valueSector === undefined) {
            resultados = { phone: data.phone, name: data.name }
            setValueSector(parroquias.sector)
        } else if (valueSector.data.length === 0) {
            resultados = { ...data, parroquia: valueSector.parroquia_id, sector: valueSector.sector_id }
            setValueSector(parroquias.sector)
        } else {
            resultados = { ...data, parroquia: valueSector.data[0].id, sector: valueSector.sector_id };
            setValueSector(parroquias.sector)
        }

        const url = `${endpoint}/cliente_public/`
        await axios.post(url, resultados, {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' }
        }).then(() => {
            toast.success(`Registro satisfactorio!`, toastOptions);
            reset()
            setCargando(false)
        }).catch((err) => {
            setCargando(false)
            const error = validError(err.response.data)
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, toastOptions);
            setCargando(false)
        })
    }



    const buscarNumero = async () => {
        if (watch('phone').length > 10) {

            const url = `${endpoint}/cliente_public/?search=${watch('phone')}`
            await axios.get(url).then((response) => {
                if (response.data[0].phone.length > 0) {
                    toast.success(`El numero ${response.data[0].phone} ya esta registrado`, toastOptions);
                }
            }).catch(() => {
                if (watch('phone').length > 11) {
                    toast.warning(`El numero ingresado tiene mas de 11 digitos`, toastOptions);
                }
                if (watch('phone').length === 11) {
                    toast.info(`El numero ${watch('phone')}, no ha sido registrado`, toastOptions);
                }

            })
        }
    }

    useEffect(() => {
        buscarNumero();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('phone')])




    return (

        <form onSubmit={handleSubmit(onSubmit)} className='sm:w-2/3 w-full px-4 lg:px-0 mx-auto flex flex-col space-y-4'>
            <div className='mb-3 pt-2 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Teléfono  <span className='text-gray-dark text-xs font-medium'> - (Ej. 04149999999)</span> </label>
                <input
                    id='phone'
                    type='number'
                    placeholder='Ingrese un Teléfono'
                    className='bg-gray-200 rounded w-full text-gray-700 focus:outline-none placeholder:text-center border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                    {...register('phone', { required: true })}

                />
                {errors.phone && (
                    <div className='text-red-900 p-1 text-sm'>Debe ingresar un Teléfono</div>
                )}
            </div>

            <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3' >Nombre y Apellido</label>
                <input
                    id='name'
                    type='text'
                    placeholder='Ingrese su nombre y apellido'
                    {...register('name')}
                    className='bg-gray-200 rounded w-full text-gray-700 placeholder:text-center focus:outline-none border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                />
            </div>

            <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Parroquia - Sector</label>
                <AutocompleteSector parroquias={parroquias} valueSector={valueSector} setValueSector={setValueSector} />

            </div>

            {cargando ? (
                <Loading cargando={cargando} />
            ) : (
                <button
                    disabled={cargando}
                    className='bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200'
                    type='submit'
                >
                    Registrar
                </button>
            )}
        </form>
    )
}
