import { Fragment, useContext, useState } from 'react';
import axios from 'axios'

/**Toast */
import { toast } from 'react-toastify';
import { saludo, toastOptions } from '../../constants/helpers';
/**Archivos de autentificacion */
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/types';
/**imagenes */
import { images } from '../../constants/images'
/**iconos */
import { BsXCircle } from 'react-icons/bs'
/**archivos constantes */
import { endpoint } from '../../constants/endpoint';
/**Componentes */
import { FormularioRegistro } from '../../components/formulario/FormularioRegistro'
import { FormularioEdit } from '../../components/formulario/FormularioEdit';
import { Loading } from '../../components/loading/Loading';
/**Cookies */
import CookieService from '../../services/CookieService';
import { Tab } from '@headlessui/react';
import { tabSelectAdmin, tabSelectUser } from '../../constants/navigation';
import { FormularioEstadisticas } from '../../components/formulario/FormularioEstadisticas';

export const Formulario = () => {

  /** manejar los valores user y dispatch*/
  const { dispatch } = useContext(AuthContext);
  /**obtener el user */
  const { user, token } = CookieService.get('user');
  /**Estado de cargando */
  const [cargando, setCargando] = useState(false)
  /**Logout action para salir */
  const handlesLogout = async () => {
    setCargando(true)
    const url = `${endpoint}/logout/`;
    await axios.get(url, {
      headers: { 'Authorization': `Token ${token}`, }
    }).then((response) => {
      CookieService.remove('user')
      dispatch({ type: types.logout })
      toast.success(`${response.data.session_message}`, toastOptions);
      setCargando(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  /**Funcion de clases Tailwind */
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <Fragment>
      {cargando === true && (<Loading cargando={cargando} />)}
      <section className='min-h-screen flex items-stretch text-white'>
        <div className='lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover bg-center relative items-center ' style={{ backgroundImage: `url(${images.logoNetwork8})` }}>
          <div className='absolute bg-black opacity-40 inset-0 z-0' />
          {/* <div className='w-full px-24 z-10'>

            <p className='text-3xl my-4'></p>
          </div> */}
          <div className='bottom-0 absolute p-4 text-center right-0 left-0 flex justify-center space-x-4' />
        </div>

        <div className='lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0 bg-black' >
          <div className='absolute lg:hidden z-10 inset-0 bg-gray-500  bg-no-repeat bg-cover bg-center items-center' style={{ backgroundImage: `url(${images.logoNetwork8})` }}>
            <div className='absolute bg-black opacity-60 inset-0 z-0'></div>
          </div>
          <div className='p-6 absolute top-0 right-0'>

            <button
              onClick={handlesLogout}
              className='inline-flex py-2 px-6 rounded-full shadow-lg text-white bg-red-700 hover:bg-red-700/80 ease-linear transition-all duration-300'
            >
              Salir <BsXCircle className='inline-flex text-base ml-2 my-auto' />
            </button>
          </div>

          <div className='w-full py-6  z-20'>
            <h1 className='my-6 mb-auto'>
              <img src={images.logoNetwork6} alt='g-network' />
            </h1>
            <h4>N° HGTS-00558</h4>
            <div className='flex py-6 space-x-4 justify-center font-bold'>
              {saludo() + ', ' + user.name}
            </div>

            <div className='p-6 block lg:hidden absolute top-0 right-0'>
              <button
                onClick={handlesLogout}
                className='inline-flex py-2 px-6 rounded-full shadow-lg text-white bg-red-700 hover:bg-red-700/80 ease-linear transition-all duration-300'
              >
                Salir <BsXCircle className='inline-flex text-base ml-2 my-auto' />
              </button>
            </div>

            <Tab.Group>
              <Tab.List className="flex space-x-1 mb-6">
                {user.rol === 'usuario' ? (
                  <Fragment>
                    {tabSelectUser.map((item) => (
                      <Tab
                        key={item.id}
                        className={({ selected }) =>
                          classNames(
                            'w-full py-2.5 text-base font-medium text-gray-300 rounded-lg',
                            selected
                              ? 'bg-white/[0.12] text-white shadow'
                              : 'text-white hover:bg-white/[0.12] hover:text-white ease-linear transition-all duration-300'
                          )
                        }
                      >
                        {item.nombre}
                      </Tab>
                    ))}

                  </Fragment>
                ) : (
                  <Fragment>
                    {tabSelectAdmin.map((item) => (
                      <Tab
                        key={item.id}
                        className={({ selected }) =>
                          classNames(
                            'w-full py-2.5 text-base font-medium text-gray-300 rounded-lg',
                            selected
                              ? 'bg-white/[0.12] text-white shadow'
                              : 'text-white hover:bg-white/[0.12] hover:text-white ease-linear transition-all duration-300'
                          )
                        }
                      >
                        {item.nombre}
                      </Tab>
                    ))}

                  </Fragment>
                )}
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel>
                  <FormularioRegistro />
                </Tab.Panel>

                <Tab.Panel>
                  <FormularioEdit />

                </Tab.Panel>

                <Tab.Panel>
                  <FormularioEstadisticas /> 
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

          </div>
        </div>
      </section>
    </Fragment>
  )
}
