import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { CallCenter } from './CallCenter';


ReactDOM.render(
  <CallCenter />,
  document.getElementById('root')
);

