import { useContext } from 'react'
import { AuthContext } from './authContext'

import { Navigate, useLocation } from 'react-router-dom'

export const PrivateRouter = ({ children }) => {
    const { user } = useContext(AuthContext)
    const { pathname } = useLocation();

    localStorage.setItem('lasPath', pathname);
    return user.logged ? children : <Navigate to='/login' />
}