import { Fragment } from 'react'

export const FloatInputText = ({ validation, name, showName, value = '', type }) => {
    return (
        <Fragment>
            <input
                type={type}
                placeholder=' '
                id={name}
                {...validation}
                className='block p-2.5 w-full text-sm appearance-none focus:outline-none bg-transparent border focus:border-red-400 border-gray-300 rounded-md'
                defaultValue={value}
            />
            <label
                htmlFor={name}
                className='absolute top-0 text-sm bg-white p-0 -z-1 duration-200 origin-0 my-2 mx-8 font-semibold text-gray-800'
            >
              {showName}
            </label>
        </Fragment>

    )
}
