import axios from 'axios'
import { Fragment, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
/**Liberias de Tailwinds */
import { Transition } from '@headlessui/react'
/**Toast */
import { toast } from 'react-toastify'
import { toastOptions } from '../../constants/helpers'
/**Auth archivos */
import { AuthContext } from '../../auth/authContext'
import { types } from '../../types/types'
/**endpoint */
import { endpoint } from '../../constants/endpoint'
/**Imagenes */
import { images } from '../../constants/images'
/**Iconos */
import { HiMail } from 'react-icons/hi';
import { FcKey } from 'react-icons/fc';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import { FloatInputText } from '../../components/inputs/FloatInputText'


export const Login = () => {

  /**validacion de formulario y submit */
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  /**disparar la action */
  const { dispatch } = useContext(AuthContext);
  /**Estado de Cargando */
  const [cargando, setCargando] = useState(false)
  /**Estado del formulario */
  const [segundoCargando, setSegundoCargando] = useState(true)
  /** Trigger para ver la password que se introduzca */
  const [show, setShow] = useState(false)

  const onSubmit = async (data) => {
    setCargando(true)
    const url = `${endpoint}/login/`;
    await axios.post(url, data).then((response) => {
      setTimeout(() => {
        const action = {
          type: types.login,
          payload: {
            user: response.data.user,
            token: response.data.token,
          }
        }
        dispatch(action)
      }, 1000)
      setCargando(false)
      setSegundoCargando(false)
      toast.success(`${response.data.message}`, toastOptions);
    }).catch((err) => {
      toast.warning(`Hubo un error, ${err.response.data.error}`, toastOptions);
      setCargando(false)
      setSegundoCargando(true)
    })
  }

  /**Funcion de clases Tailwind */
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <Fragment>
      <div className='min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        {cargando === true ? (
          <Fragment>
            <div className='sm:flex sm:items-start justify-center items-center'>
              <img src={images.logoNetwork} alt='G-Network' className='animate-bounce h-32 w-32' />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Transition
              show={segundoCargando}
              enter='transition transform duration-500 zoom-in'
              enterFrom='translate-y-4 opacity-0'
              enterTo='translate-y-0 opacity-100'
              leave='transition-opacity duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                <img
                  className='mx-auto h-12 w-auto'
                  src={images.logoNetwork3}
                  alt='G-Network'
                />
                <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-100'>Call Center</h2>
              </div>
              <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='space-y-6'>
                      {/* input de Correo Electrónico*/}
                       <div className='relative'>
                        <HiMail className={
                          classNames(
                            watch('username') ? 'hidden' : 'opacity-100',
                            'absolute top-0 mt-3 ml-2 text-gray-400'
                          )}
                        />
                        <FloatInputText
                          type={'email'}
                          validation={register('username', { required: true })}
                          name={'Correo Electrónico'}
                          showName={'Correo Electrónico'}
                        />

                        {errors.username && (
                          <div className='text-red-800 pt-1 text-sm'>El correo electrónico es requerido</div>
                        )}

                      </div>
                      {/* input de password*/}

                      <div className='relative'>
                        <FcKey className={
                          classNames(
                            watch('password') ? 'hidden' : 'opacity-100',
                            'absolute top-0 mt-3 ml-2 text-gray-400'
                          )}
                        />
                        <FloatInputText
                          type={`${show ? 'text' : 'password'}`}
                          validation={register('password', { required: true })}
                          name={'Ingresa tu contraseña'}
                          showName={'Ingresa tu contraseña'}
                        />
                        {show ? (
                          <button type={`button`} className='absolute top-0 right-3 mt-3 ml-2 text-gray-400'
                            onClick={() => setShow(false)}>
                            <GoEye className='text-gray-2 text-xl' />
                          </button>
                        ) : (
                          <button type={`button`} className='absolute top-0 right-3 mt-3 ml-2 text-gray-400'
                            onClick={() => setShow(true)}>
                            <GoEyeClosed className='text-gray-2 text-xl' />
                          </button>
                        )}
                        {errors.password && (
                          <div className='text-red-800 pt-1 text-sm'>La contraseña es requerida</div>
                        )}
                      </div>

                      <div>
                        <button
                          type='submit'
                          className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ease-linear transition-all duration-300'
                        >
                          Entrar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition>
          </Fragment>
        )}
      </div>
    </Fragment>

  )
}