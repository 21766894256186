import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
/**archivos constante */
import { endpoint } from '../../constants/endpoint';
/**Componentes */
import { AutocompleteSector } from '../../components/autocomplete/AutocompleteSector';
/**Iconos */
import {  BsFillFileEarmarkBarGraphFill, BsHouseFill, BsSearch } from 'react-icons/bs';
/**PDF */
// import { reportsParroquiasSectores } from '../../reports/parroquias-sectores/reportsParroquiasSectores';
// import { reportsTotal } from '../../reports/total/reportsTotal';



export const FormularioEstadisticas = () => {

  /**Obtener las parroquias y sectores  */
  const [parroquias, setParroquias] = useState([]);
  /**Valor para el submit del autocomplete */
  const [valueSector, setValueSector] = useState(parroquias.sector);
  /**Filtrado de busqueda por PDF */
  const [filterValuePDF, setFilterValuePDF] = useState([])
  // const [todosPDF, setTodosPDF] = useState([])
  /**Show botones */
  const [showAll, setShowAll] = useState(false)
  const [show, setShow] = useState(false)



  const getParroquiasSectores = async () => {
    const url = `${endpoint}/sector3/?format=json`;
    await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setParroquias(response.data)
    }).catch((err) => {
      console.log(err, ' aqui esta el error ')
    })
  }

  useEffect(() => {
    getParroquiasSectores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtrarClientesParroquiasSectoresPDF = async () => {
    let resultadoURL = {}
    if (valueSector.data.length === 0) {
      resultadoURL = `${endpoint}/reporte/?parroquia=${valueSector.parroquia_id}&sector=${''}&documento=1`
    } else {
      resultadoURL = `${endpoint}/reporte/?parroquia=${''}&sector=${valueSector.sector_id}&documento=1`
    }
    await axios.get(resultadoURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setFilterValuePDF(response.data.data_filter);
      setShowAll(false);
    }).catch((err) => {
      console.log(err)
    })

  }


  const filtrarClientesParroquiasSectoresEXCEL = async () => {
    let resultadoURL = {}
    if (valueSector.data.length === 0) {
      resultadoURL = `${endpoint}/reporte/?parroquia=${valueSector.parroquia_id}&sector=${''}&documento=2`
    } else {
      resultadoURL = `${endpoint}/reporte/?parroquia=${''}&sector=${valueSector.sector_id}&documento=2`
    }
    window.location.assign(`${resultadoURL}`);
    setShowAll(false);
  }



  useEffect(() => {
    filtrarClientesParroquiasSectoresPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSector])


  const showTodasLasParroquiasSectores = async () => {
    const resultadoURL = `${endpoint}/reporte/?parroquia=${''}&sector=${''}&documento=1`
    await axios.get(resultadoURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      // setTodosPDF(response.data);
      setShow(false);
    }).catch((err) => {
      console.log(err)

    })
  }

  useEffect(() => {
    showTodasLasParroquiasSectores()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clientesParroquiasSectoresEXCEL = async () => {
    const resultadoURL = `${endpoint}/reporte/?parroquia=${''}&sector=${''}&documento=2`
    window.location.assign(`${resultadoURL}`);
    setShow(false);
  }




  return (
    <Fragment>
      <div className='sm:w-2/3 w-full px-4 lg:px-0 mx-auto flex flex-col space-y-4'>


        <button
          type='button'
          className='mt-2 bg-rose-900 hover:bg-rose-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200'
          onClick={() => setShowAll(!showAll)}
        >
          <BsHouseFill className='inline-flex mr-1 mb-1' /> Todas las parroquias
        </button>

        {showAll === true ? (
          <div className='mt-3 flex p-2 w-full justify-center space-x-10'>
            {/* <button
              type='button'
              className='inline-flex text-2xl bg-red-600 px-4 py-4 rounded-full hover:bg-red-700 text-white font-semibold transition-rotation duration-300 hover:rotate-45 ease-in-out'
              onClick={() => reportsTotal(todosPDF)}
            >
              <BsFileEarmarkPdfFill />
            </button> */}

            <button
              type='button'
              className='inline-flex text-2xl bg-green-600 px-4 py-4 rounded-full hover:bg-green-700 text-white font-semibold transition-rotation duration-300 hover:rotate-45 ease-in-out'
              onClick={() => clientesParroquiasSectoresEXCEL()}
            >
              <BsFillFileEarmarkBarGraphFill />
            </button>
          </div>
        ) : (null)}

        <button
          type='button'
          className='mt-2 bg-rose-900 hover:bg-rose-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200'
          onClick={() => setShow(!show)}
        >
          <BsSearch className='inline-flex mr-1 mb-1' /> Buscar
        </button>


        {show === true && (
          <div className='mb-3 pt-2 rounded bg-gray-200'>
            <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Parroquia - Sector</label>
            <AutocompleteSector
              parroquias={parroquias}
              valueSector={valueSector}
              setValueSector={setValueSector}
            />
          </div>

        )}

      </div>


      {filterValuePDF.length > 0 && (
        <div className='flex p-2 w-full justify-center space-x-10'>

          {/* <button
            type='button'
            className='inline-flex text-2xl bg-red-600 px-4 py-4 rounded-full hover:bg-red-700 text-white font-semibold transition-rotation duration-300 hover:rotate-45 ease-in-out'
            onClick={() => reportsParroquiasSectores(filterValuePDF)}
          >
            <BsFileEarmarkPdfFill />
          </button> */}
          <button
            type='button'
            className='inline-flex text-2xl bg-green-600 px-4 py-4 rounded-full hover:bg-green-700 text-white font-semibold transition-rotation duration-300 hover:rotate-45 ease-in-out'
            onClick={() => filtrarClientesParroquiasSectoresEXCEL()}
          >
            <BsFillFileEarmarkBarGraphFill />
          </button>
        </div>
      )}
    </Fragment>
  )
}
