import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'


export const AutocompleteSector = ({ parroquias, valueSector, setValueSector, personData = null }) => {
    const [query, setQuery] = useState('')

    const filteredSector =
        query === ''
            ? parroquias
            : parroquias.filter((item) =>
                item.name
                    .toLowerCase()
                    .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                    .includes(query.toLowerCase())
            )

    useEffect(() => {
        if (personData !== null) {
            /** Verificamos si la persona tiene sector */
            if (personData[0].id_sector !== null) {
                const filteredSector = parroquias.filter((value) => {
                    return value.sector_id === personData[0].id_sector;
                });

                setValueSector(filteredSector[0])
            }

            if (personData[0].id_parroquia !== null && personData[0].id_sector === null) {
                /** Verificamos si la persona tiene parroquia */
                const filteredParroquia = parroquias.filter((value) => {
                    return value.parroquia_id === personData[0].id_parroquia;
                });
                setValueSector(filteredParroquia[0])
            }
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personData])

    return (
        <Combobox value={valueSector} onChange={setValueSector}>
            <div className='relative mt-1'>
                <div className='relative w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none sm:text-sm overflow-hidden'>
                    <Combobox.Input
                        className='bg-gray-200  w-full text-gray-700 focus:outline-none placeholder:text-center border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                        displayValue={(item) => item.data.length !== 0 ? (item.name + ' - ' + item.data[0].name) : (item.name)}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder='Busque por parroquia o sector'
                        autoComplete='off'
                    />

                </div>
                <Transition
                    as={Fragment}
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options className='absolute  w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-32 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm '>
                        {filteredSector.length === 0 && query !== '' ? (
                            <div className='cursor-default select-none relative py-2 px-4 text-gray-700'>
                                No hay resultados
                            </div>
                        ) : (
                            filteredSector.map((item, index) => (
                                <Combobox.Option
                                    key={index.toString()}
                                    className={({ active }) =>
                                        `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? 'text-white bg-red-600' : 'text-gray-900'}`
                                    }
                                    value={item}
                                >
                                    {({ selected, active }) => (
                                        <Fragment>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                            >
                                                {item.name} {item.data.length !== 0 && (' - ' + item.data[0].name)}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-red-600'}`}
                                                >
                                                    <CheckIcon className='w-5 h-5' aria-hidden='true' />
                                                </span>
                                            ) : null}
                                        </Fragment>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}
