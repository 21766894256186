import { BrowserRouter, Routes, Route } from 'react-router-dom'

/**Archivos de rutas */
import { PrivateRouter } from '../auth/PrivateRouter';
import { PublicRouter } from '../auth/PublicRouter';


/**Componentes de Auth */
import { Login } from '../pages/auth/Login'
import { Main } from './Main';


export const AuthRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={
                    <PublicRouter>
                        <Login />
                    </PublicRouter>
                } />

                <Route path='/*' element={
                    <PrivateRouter>
                        <Main />
                    </PrivateRouter>
                } />
            </Routes>

        </BrowserRouter>
    )
}
